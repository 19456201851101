import React, { useCallback } from 'react';
import { useState } from 'react';
import Corp from './Corp';
const Main = ({merveille,etage,setEtage,civilMilitaire,science}) => {
    
    /* console.log('main')
    console.log(etage) */
    const [curent, setCurent] = useState('merveille')
    const handelClick = useCallback((set)=>{
        setCurent(set)
    },[]) 
    

    return(
        <div id="main">
           <nav id="nav_bar" className="flex merveille">
                <div id="nav_merveille" onClick={()=>{handelClick('merveille')}}>Merveille</div>
                <div id="nav_civil_millitaire" onClick={()=>{handelClick('civil')}}>Civil/Militaire</div>
                <div id="nav_Sience" onClick={()=>{handelClick('science')}}>Science</div>
            </nav>

            <div className='flex' id="corp">
            <Corp c={curent} merveille={merveille} etage={etage} setEtage={setEtage} civilMilitaire={civilMilitaire} science={science}></Corp>
            </div> 
        </div>
        
    )
};

export default Main;