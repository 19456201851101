import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import socket from '../../soket/Socket';
import { createContext } from 'react';
import { useContext } from 'react';
import { mesageContext } from "../../contexte/mesage/MesageContext"

export const mheupartiecontext = createContext()
console.log('context')
const MheuPartieContext = () => {
    const {Mesage, setMesage} = useContext(mesageContext)
    const nav = useNavigate()
    const [partie,setPartie] = useState(undefined)
    const [joueurs,setJoueurs] = useState(undefined)
    const [loading, setLoding] = useState(true)
    const [color,setColor] = useState([])
    console.log(loading)
    useEffect(()=>{
        if(loading){
            let copymesage ={...Mesage}
            copymesage.mesage = undefined
            copymesage.type = undefined
            setMesage(copymesage)
            socket.emit('mheu/get-info-partie', (reponce)=>{
                if(!reponce.err){
                    setPartie(reponce.partie)
                    setJoueurs(reponce.joueurs)
                    setColor(reponce.color)
                    console.log(reponce.partie.statut)
                    console.log(reponce)
                    setLoding(false)
                    nav(`/6quiprend/partie/${reponce.partie.statut}`)
                }else{
                    nav("/6quiprend/rejoindre")
                }
                
            })
        }
    },[])

    return (
        <mheupartiecontext.Provider value={{partie,joueurs,setJoueurs,setPartie,loading,setLoding,color,setColor}}>
            <Outlet></Outlet>
        </mheupartiecontext.Provider>
    );
};

export default MheuPartieContext;