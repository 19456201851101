import React from 'react';
import { Link} from 'react-router-dom';
import { useContext } from 'react';
import { authcontext } from '../../contexte/auth/AuthContext';

function MheuHome(props) {
    const {user} = useContext(authcontext)
    return (
        <div className='flex menu'>
          <li>
            <ul><Link to='/6quiprend/rejoindre'><button className='menu-btn'>Rejoindre une partie</button></Link> </ul>
            <ul><Link to='/6quiprend/cree'><button className='menu-btn'>Crée une partie</button></Link></ul>
            {user.role === 'admin' && <ul><Link to='/admin/menu'><button className='menu-btn'>Administration</button></Link> </ul>} 
          </li>
           
          
          
        </div>
    );
}

export default MheuHome;