import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';

const CardPartie = ({partie,changeStatue}) => {
    console.log(partie)
    const [statue,setStatue] = useState(partie.statut)
    const handelChange = useCallback((e)=>{
        let statue = e.target.value
        setStatue(statue)
    },[])
    return (
        <div key={partie.id} className='flex card-partie'>
                            <div className='nom-partie'>Nom de la partie : {partie.nom}</div>
                            <div className='info-partie'>
                                <div>Cree par : {partie.username}</div>
                                <div>Nombre de joueurs {partie.NB_joueur}/10</div>
                                <div><label htmlFor="statue">Statue :</label>    
                                    <select name="statue" id="statue" value={statue} onChange ={(e)=>{handelChange(e);changeStatue(e.target.value,partie.id)} } >
                                        <option value="inconu">Inconu</option>
                                        <option value="terminer">Terminer</option>
                                        <option value="loby">Loby</option>
                                        <option value="fin">Fin</option>
                                    </select>
                                    </div>

                            </div>

        </div>
    );
};

export default CardPartie;