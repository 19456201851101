import React from 'react';
import Card from './Card';

const Civil = ({civilMilitaire}) => {
    
    return (
        <div>
            <section className="civils flex">
                <Card styles ={'civil'} state={civilMilitaire.chat} type={'checkbox'} n={1}>
                    <span className="nom">Pion chat:</span> 
                    <div className='img'> <img src="/Chat.png" alt="" /></div>
                    <div className="effet">A la fin
                                        de
                                        la partie, raporte 2 points de victoire</div>
                </Card>
                
                <Card styles ={'civil'} state={civilMilitaire.carteBleue} type={'select'} n={20}>
                    <span className="nom">Carte bleu:</span>
                    <div className='img'> <img src="/civile.png" alt="" /></div>
                     <span className="effet">A la fin
                                        de
                                        la partie, raporte 3 points de victoire</span>
                </Card>
                <Card styles ={'civil'} state={civilMilitaire.carteChat} type={'select'} n={20}>
                    <span className="nom">Carte bleu (chat):</span> 
                    <div className='img'> <img src="/civileChat.png" alt="" /></div>
                    <span className="effet">A
                                        la
                                        fin de la partiee, raporte 2 points de victoire.</span>
                </Card>
                
            </section>
            
            <section  className="millitaires flex">
                <Card styles ={'millitaire'} state={civilMilitaire.militaire} type={'select'} n={20}>
                    <span className="nom">Jeton militaire:</span>
                    <div className='img'> <img src="/militaire.png" alt="" /></div> 
                    <span className="effet">A
                                        la
                                        fin de la partiee, raporte 3 points de victoire.</span>
                </Card>
            </section>
            
        </div>
    );
};

export default Civil;