import React from 'react';
import Civil from './Civil';
import Merveille from './Merveille';
import Science from './Science';
const Corp = ({merveille, c,etage,setEtage,civilMilitaire,science}) => {
    switch (c) {
        case 'merveille':
             return <Merveille nom={merveille.nom} etages={merveille.etages} state={etage} setState ={setEtage}/>
           

        case 'civil':
            return <Civil civilMilitaire={civilMilitaire}/>
            

        case 'science':
            return <Science science={science}/>
            
        default:
            return <Merveille nom={merveille.nom} etages={merveille.etages} state={etage} setState ={setEtage} />
            
    }
};

export default Corp;