import React from 'react';

const Cartouche = ({nom, description}) => {
    return(
        <div id="cartouche" className={nom}>
            <div id="cartouche_nom">{nom}</div>
            <div id="cartouche_description">{description}</div>
        </div>
    )
};

export default Cartouche;