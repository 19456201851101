import React from 'react';
import { useContext } from 'react';
import { authcontext } from '../../contexte/auth/AuthContext';
import socket from '../../soket/Socket';
import MheuSelectColor from './MheuSelectColor';

const MheuJouerRow = ({joueur,pret,colors,setColor}) => {
    const {user} = useContext(authcontext)
    function handelSelect(e) {
        pret(0)
        const value = parseInt(e.target.value)
        socket.emit('mheu/update-mode',{
            id:user.id,
            mode:value
        })
        
    }
    return (
        <tr>
            <td className="couleur" data-color = {joueur.couleur} colSpan='2'>{joueur.username}</td>
            <td className="couleur" data-color={joueur.couleur}>
                {joueur.id_utilisateur != user.id? 
                <div className="couleur" data-color ={joueur.couleur}></div>:
                <MheuSelectColor setColor= {setColor} pret={pret} joueur={joueur} colors= {colors}></MheuSelectColor>
                }
            </td>
            {joueur.id_utilisateur === user.id ?
            <td>
                <select name="mode" id="mode" onChange={(e)=>{handelSelect(e)}}>
                    <option value = '0'>Auto</option>
                    <option value = '1'>Manuel</option>
                </select>
            </td>:
            <td colSpan='1'>{joueur.mode ? 'Manuel':'Auto'}</td>
            }
            <td className={joueur.pret ? "Pret": 'Nom_pret'} colSpan='2'>{joueur.pret ? "Pret": 'Non pret'}</td>
        </tr>
    );
};

export default MheuJouerRow;