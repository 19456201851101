import React from 'react';

const Cardfin = ({img,type,nom,pts}) => {
    return (
        <div>
            <div className={"best flex " + type}>
                    <img src={img} alt=""/> <span>Meilleur joueur : {nom}  avec :  {pts} pts</span>
            </div>
        </div>
    );
};

export default Cardfin;