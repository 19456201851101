import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import socket from '../../soket/Socket';
import { authcontext } from '../../contexte/auth/AuthContext';
import MheuScorerow from "../../component/6quiprend/MheuScorerow"
import { mheupartiecontext } from "../../contexte/auth/MheuPartieContext"
import { useNavigate } from 'react-router-dom';
import MheuLoby from './MheuLoby';

const MheuFin = () => {
    const nav  = useNavigate()
    const {partie} =  useContext(mheupartiecontext)
    const {user} = useContext(authcontext)
    const [loading2,setLoding2] = useState(true)
    

    const [clasement,setClasement] = useState([])
    useEffect(()=>{
        socket.emit('mheu/get-clasement',(clasement)=>{
            console.log('clasement',clasement)
            setClasement(clasement)
            setLoding2(false)
        })
    },[])

    useEffect(()=>{
        socket.on('mheu/home',()=>{
            console.log('home')
            nav('/6quiprend/home')
        })

        return ()=>{
            socket.off('home')
        }
    },[nav])

    function terminer(){
        socket.emit('mheu/terminer')
    }
   /*  socket.on('startt',()=>{
        console.log('start')
        nav('/7wonder/partie/score')
    }) */
    let tab  = []
    for (let  i= 0; i < clasement.manche; i++) {
        console.log('for',i)
        tab.push(<td key={'M'+i}>M{i+1}</td>)
    }

    if(loading2){
        return(
            <div className="loading">
                LOADINGG
            </div>
        )
    }else{

        return (
    
                    <div className="partie">
                        <table>
                            <thead>
                                <tr>
                                    <td>Joueur</td>
                                    {tab}
                                    <td>Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {clasement.joueurs.map((joueur)=>(
                                    <MheuScorerow key={joueur.score.id_score} joueur={joueur} />
                                ))}
                            </tbody>
                        </table>
                        {user.id === partie.id_utilisateur && <button onClick={()=>{terminer()}}>Terminer</button>}
                        <div className='flex centre'>
                            {<MheuLoby/>}
                        </div>
                    </div>
        );
    }
};

export default MheuFin;