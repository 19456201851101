import React from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
    return (
        <div>
            <h1>Administration</h1>
            <Link to='demande'><button className='menu-btn'>Demande de création de compte</button></Link> 
            <Link to='gererPartie6quiprend'><button className='menu-btn'>Gerer partie 6 qui prend</button></Link> 
        </div>
    );
};

export default Menu;