import React from 'react';

const ScoreRow = ({score}) => {
    return (
        <tr>
            <td >
                <div>{score.username} </div>
                <div className='nom_merveille'>{score.nom_merveille}</div>
            </td>
            <td>{score.merveille}</td>
            <td>{score.civile}</td>
            <td>{score.militaire}</td>
            <td>{score.science}</td>
            <td>{score.total}</td>
        </tr>
    );
};

export default ScoreRow;