import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { authcontext } from '../../contexte/auth/AuthContext';

function Mheu(props) {
    const {user} = useContext(authcontext)
    const nav = useNavigate()
    useEffect(()=>{
      nav('/6quiprend/home')      
    },[])
    console.log(user)
    return (
      <div>
        <Link to='/6quiprend/home'><img src="/6quiprend/6-qui-prend.jpg" className='menu_logo'/></Link>
        <Outlet></Outlet>
      </div>
        
    );
}

export default Mheu;