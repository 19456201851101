import React from 'react';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authcontext } from '../contexte/auth/AuthContext';

const Home = () => {
    const {user} = useContext(authcontext)
    console.log(user)
    const nav = useNavigate()
    function logout(){
      fetch('https://jeux-api.edo.ovh/logout',{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        method : 'post',
        credentials: 'include'  
    }).then((res)=>{
      if(res.ok){
        return res.json()
      }
    }).then((json)=>{
      console.log(json)
      window.location.reload()
    })
    }
    return (
        <div className='flex menu'>
          
          <li>
          <ul><Link to='/7wonder'><button className='menu-btn'>7wonder</button></Link> </ul>
            <ul><Link to='/6quiprend'><button className='menu-btn'>6 qui prend</button></Link></ul>
            {user.role === 'admin' && <ul><Link to='/admin/menu'><button className='menu-btn'>Administration</button></Link> </ul>}
            <ul><button className='menu-btn' type='button' onClick={()=>{logout()}}>Se déconnecter</button></ul> 
          </li>
           
          
          
        </div>
    );
};

export default Home;