import React from 'react';
import { useCallback } from 'react';
/* import { useCallback } from 'react'; */

const Card = React.memo(({state,type,children,n,styles}) => {
    
    const selcte = new Array(n)
    for (let i = 0; i < selcte.length; i++) {
        selcte[i] = i
        
    }
    
    /* const handelechange  = useCallback((e)=>{
        if(e.target.type === 'checkbox'){
            setState(!state)
        }
    }) */
    const checkbox = useCallback((n)=>{
        let element = new Array(n)
        for (let index = 0; index < element.length; index++){
            element[index] =<input key={index} type={'checkbox'} name={index} checked={state[0][index]} onChange={(e)=>{handelCheckbox(e)}}/>;  
        }
        return element
    },[n,state])
    const handelCheckbox = useCallback(function (e){
        const i = parseInt(e.target.name)
        let value = state[0].slice()
        value[i] = e.target.checked
        state[1](value)
    }, [state]) 
    const handelSelect = useCallback(function (e){
        state[1](e.target.value)
    }, [state]) 
    return (
            <section className= {styles +" flex card"}>
                            <div className="description flex"> 
                            {children}</div>
                            
                                {type === 'checkbox'? checkbox(n) :
                                type === 'select' ? 
                                <select value={state[0]} onChange={(e)=>{handelSelect(e)}}>
                                    {selcte.map((v)=>
                                        <option key={v} value={v}>{v}</option>
                                        )}
                                </select>:""
                                }
                                
                            
            </section>
        
    );
});


export default Card;