import React from 'react';

const MheuSelectColor = ({colors,pret,joueur,setColor}) => {
    const curent = joueur.couleur === 'N/A' ? '--choisisez une couleur--': joueur.couleur  
    console.log('couleur',colors)
    return (
        <select className="couleur" data-color = {joueur.couleur} name="color" id="color" value={joueur.couleur}onChange={(e)=>{setColor(e.target.value);pret(false)}}>
            <option value={joueur.couleur} >{curent}</option>
            {colors.map((color)=>(
                <option key={color} value={color} data-color={color} >
                    {color}
                </option>
            ))}
        </select>
    );
};

export default MheuSelectColor;