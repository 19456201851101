import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Alert from '../../component/Alert';
import { mesageContext } from '../../contexte/mesage/MesageContext';
import socket from '../../soket/Socket';

const Demande = () => {
    const [Demandes, setDemandes] = useState([])
    const [loading, setLoading] = useState(false)
    const {Mesage, setMesage} = useContext(mesageContext)
    useEffect(()=>{
        console.log('init demande')
        socket.emit('get-demande',(reponce)=>{
            setDemandes(reponce.demande)
            console.log(Demandes)
            setLoading(true)
        })
        return ()=>{
            let clonemesage = {...Mesage}
            clonemesage.mesage = false
            clonemesage.type = undefined
            setMesage(clonemesage)
        }
    },[])

    useEffect(()=>{
        socket.on('new_demande',({id,user})=>{
            console.log(id,user)
            const newuser = {id:id, username:user}
            let copydemande = [...Demandes]
            console.log(copydemande)
            copydemande = copydemande.concat(newuser)
            setDemandes(copydemande)
            console.log(Demandes)
            return()=>{
                let clonemesage = {...Mesage}
                clonemesage.mesage = false
                clonemesage.type = undefined
                setMesage(clonemesage)
            }
        },[])

        socket.on('del_demande',(id,mesage)=>{
            let clonemesage = {...Mesage}
                clonemesage.mesage = false
                clonemesage.type = undefined
                setMesage(clonemesage)
            console.log('del')
            console.log(Demandes,id)
            let copydemande = [...Demandes]
            const i = copydemande.findIndex((e)=>e.id == id)
            console.log(i)
            if(i >- 1){
                copydemande.splice(i,1)
                setDemandes(copydemande)
            }
            console.log(mesage)
            setMesage(mesage)
        })

        return ()=>{
            socket.off('new_demande')
            socket.off('del_demande')
            
        }

    },[Demandes,setDemandes])

    
    function sumbit(e){
        let clonemesage = {...Mesage}
                clonemesage.mesage = false
                clonemesage.type = undefined
                setMesage(clonemesage)
        e.preventDefault()
        const form = new FormData(e.target)
        const statu = parseInt(form.get('statu')) 
        const user = {
            id : e.target.dataset.id,
            role : form.get('role')
        }
        if(statu){
            socket.emit('acept-demande', user)
        }else{
            socket.emit('refuser-demande', user) 
        }
        console.log(form.get('statu'))
    }

    return (
        <div className='flex demandes'>
            <h1>Demandes en attente</h1>
             <Alert mesage={Mesage}/>
            {loading ? Demandes.map(demande=>(
                <form className='demande flex' onSubmit={(e)=>{sumbit(e)}} data-id ={demande.id} key={demande.id}>
                    
                    <div className="info_demande">
                        <span className='username'>username: {demande.username} </span>
                    </div>
                        <div className='form_groupe'>
                            <label>Role: </label>  
                            <select name="role">
                                    <option value="user">user</option>
                                    <option value="admin">admin</option>
                            </select>
                        </div>
                        <div className='form_groupe'>
                            <div className='flex radio'>
                                <label>Refuser</label>
                                <input type="radio" value='0' name='statu' />
                            </div>
                            <div className='flex radio'>
                                <label>Accepter</label>
                                <input type="radio" value='1' name='statu' />
                            </div>
                        </div>
                        <button type="submit">Envoyer</button>
                    
                </form>
            )): <span>chargement</span>}
        </div>
    );
};

export default Demande;