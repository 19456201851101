import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ScoreRow from '../../component/clasement/ScoreRow';
import { authcontext } from '../../contexte/auth/AuthContext';
import { partiecontext } from '../../contexte/auth/PartieContext';
import socket from '../../soket/Socket';

const Clasement = () => {
    const nav = useNavigate()
    const [scores, setScores] = useState([])
    const [loading,setLoading] = useState(true)
    const {partie,setPartie} =  useContext(partiecontext)
    const {user} = useContext(authcontext)
    useEffect(()=>{
        socket.emit('get-score',(reponce)=>{
            console.log(reponce)
            setScores(reponce)
            setLoading(false)   
        })   
    },[])
    
    useEffect(()=>{
        socket.on('score-update',(update)=>{
            console.log('update score')
            let clonescores = [...scores]
            const i = clonescores.findIndex((score)=>(score.id === update.id))
            if(i > -1){
                clonescores[i].merveille = update.merveille
                clonescores[i].civile = update.civil
                clonescores[i].militaire = update.militaire
                clonescores[i].science = update.science
                clonescores[i].total = update.total
                setScores(clonescores)
            }
        })

        socket.on('fin',(tour)=>{
            nav('/7wonder/partie/fin')
            let clonePartie = {...partie}
            clonePartie.ntour = tour
            setScores(clonePartie)
        })

        return ()=>{
            socket.off('score-update')
            socket.off('fin')
        }
            
    },[setScores,partie,setPartie,nav,scores])
    

    function fin($){
        socket.emit('fin-'+$)
    }

    

   const classer = function(par){
        switch (par) {
            case 'total':
                return (a,b)=> b.total - a.total;
                
            case 'civile':
                return (a,b)=> b.civile - a.civile;
                
            case 'militaire':
                return (a,b)=> b.militaire - a.militaire;
                
            case 'science':
                return (a,b)=> b.science - a.science;
               
                case 'merveille':
                return (a,b)=> b.merveille - a.merveille;
                
            default:
               
        }
   }
    let scoreSort = scores.slice().sort(classer('total'))
   if(loading){
    return(
        <div>loading</div>
    )
   }else{
       return (
           <div className='classement flex centre'>
               <table>
                   <thead>
                       <tr>
                           <th>Joueur</th>
                           <th><img src="/merveille.png" alt='merveille'/></th>
                           <th><img src="/civile.png" alt='civile'/></th>
                           <th><img src="/militaire.png" alt='militaire'/></th>
                           <th><img src="/science.png" alt='science'/></th>
                           <th><img src="/sigma.png" alt='sigma'/></th>
                       </tr>
                   </thead>
                   <tbody>
                       {
                           scoreSort.map((score)=>(
                               <ScoreRow key={score.merveilleNom} score ={score}></ScoreRow>    
                           ))
                       }
                   </tbody>
               </table>
               {partie.id_utilisateur === user.id && <div>
                                                        <button onClick={()=>{fin("tour")}}>Fin du tour</button>
                                                    </div>}
           </div>
       );

   }

};

export default Clasement;