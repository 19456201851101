import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import socket from '../../soket/Socket';

const CreePartie = () => {
    const nav = useNavigate()
    function creePartie(e){ 
        e.preventDefault()
        const form = new FormData(e.target)
        const partie = {
            nom: form.get('nom_partie'),
            alt: form.get('mode_alt'),
        }
        if(partie.nom){
            socket.emit('cree-partie',partie,(rep)=>{
                console.log(rep)
                if(rep){
                    nav('/7wonder/partie/loby')
                }
            })
        }
        
    }
    return (
        <div className='flex'>
            <div>
                <form onSubmit={(e)=>{creePartie(e)}}>
                    <div>

                        <div>
                            <label htmlFor="nom_partie">Nom de la partie: </label>
                            <input name='nom_partie' id='nom_partie' type="text" required/>
                        </div>
                        <div>
                            <label htmlFor="mode_alt">Distribution: </label>
                            <select name="mode_alt" id="mode_alt">
                                <option value="0">Avec répétition</option>
                                <option value="1">Sans répétition</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <button className='menu-btn' type="submit">Crée la partie</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreePartie;