import React from 'react';
import Score from './page/7wonder/score'
import './index.css'
import Clasement from './page/7wonder/Casement';
import Fin from './page/7wonder/Fin';
import Loby from './page/7wonder/Loby';
import Rejoindre from './page/7wonder/Rejoindre';
import Login from './page/Login';
import { Link, Route, Routes } from 'react-router-dom';
import AuthContext from './contexte/auth/AuthContext';
import Private from './contexte/auth/Private';
import Home from './page/Home';
import CreePartie from './page/7wonder/CreePartie';
import Administration from './page/admin/Administration';
import Singin from './page/Singin';
import Demande from './page/admin/Demande';
import Menu from './page/admin/Menu';
import PartieContext from './contexte/auth/PartieContext';
import MesageContext from './contexte/mesage/MesageContext';
import Wonder from './page/7wonder/7wonder';
import Mheu from './page/6quiprend/mheu';
import Homewonder from './page/7wonder/Homewonder';
import MheuHome from './page/6quiprend/MheuHome';
import MheuRejoindre from './page/6quiprend/MheuRejoindre';
import MheuCreePartie from './page/6quiprend/MheuCreePartie';
import MheuLoby from './page/6quiprend/MheuLoby';
import MheuPartieContext from './contexte/auth/MheuPartieContext';
import MheuScore from './page/6quiprend/Mheuscore';
import MheuFin from './page/6quiprend/MheuFin';
import GererPartieMheu from './page/admin/GererPartieMheu';
export const ID = 1 

export let score ={
  merveille:0,
  civil:0,
  militaire:0,
  science:0,
  total:0
}
export default function App() {
  return (
    <div className="App flex">
      <MesageContext>
        <AuthContext>
          <Routes>
            <Route path='/login' element = {<Login/>}/>
            <Route path='/singin' element = {<Singin/>}/>
            <Route path='/' element = {<Private/>}>
              <Route path='/6quiprend' element = {<Mheu/>}>
                <Route path='/6quiprend/home' element = {<MheuHome/>}/>
                <Route path='/6quiprend/rejoindre' element = {<MheuRejoindre/>}/>
                <Route path='/6quiprend/cree' element = {<MheuCreePartie/>}/>
                <Route path='/6quiprend/partie' element = {<MheuPartieContext/>}>
                  <Route path='/6quiprend/partie/loby' element = {<MheuLoby/>}/>
                  <Route path='/6quiprend/partie/score' element = {<MheuScore/>}/>
                  <Route path='/6quiprend/partie/clasement' element = {<Clasement/>}/>
                  <Route path='/6quiprend/partie/fin' element = {<MheuFin/>}/>  
              </Route>
              </Route>

              <Route path='/7wonder' element = {<Wonder/>}>
              <Route path='/7wonder/home' element = {<Homewonder/>}/>
              <Route path='/7wonder/rejoindre' element = {<Rejoindre/>}/>
              <Route path='/7wonder/cree' element = {<CreePartie/>}/>
              <Route path='/7wonder/partie' element = {<PartieContext/>}>
                <Route path='/7wonder/partie/loby' element = {<Loby/>}/>
                <Route path='/7wonder/partie/score' element = {<Score/>}/>
                <Route path='/7wonder/partie/clasement' element = {<Clasement/>}/>
                <Route path='/7wonder/partie/fin' element = {<Fin/>}/>  
              </Route>

            </Route>
              <Route path='/admin' element = {<Administration/>}>
                <Route path='/admin/menu/demande' element = {<Demande/>}/>
                <Route path='/admin/menu' element = {<Menu/>}/>
                <Route path='/admin/menu/gererPartie6quiprend' element = {<GererPartieMheu></GererPartieMheu>}/>
              </Route>
              <Route path='/home' element = {<Home/>}/>
            </Route>
            
          </Routes>
        </AuthContext>
      </MesageContext>
          
    </div>
  );
}


