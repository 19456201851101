import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../component/Alert';
import { mesageContext } from '../contexte/mesage/MesageContext';

const Singin = () => {

    useEffect(()=>{
        return()=>{
            let clonemesage = {...Mesage}
            clonemesage.mesage = false
            clonemesage.type = undefined
            setMesage(clonemesage)
        }
    },[/* Mesage,setMesage */])
    
    const {Mesage, setMesage} = useContext(mesageContext)
    function submit(e){
        let clonemesage = {...Mesage}
                clonemesage.mesage = false
                clonemesage.type = undefined
                setMesage(clonemesage)
        e.preventDefault()
        const form = new FormData(e.target)
        e.target.reset()
        const body = {
            uername: form.get('username'),
            password: form.get('password')
        }
        if(body.uername && body.password){
            fetch('https://jeux-api.edo.ovh/sigin',{
            
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                method : 'post',
                body : JSON.stringify(body) ,
                
            }).then((res)=>{
                clonemesage = {...Mesage}
                if(res.status === 201 || res.status === 200)  {
                    clonemesage.type = 'ok'
                }else{
                    clonemesage.type = 'nok'
                }
                return res.text()   
            }).then((data)=>{
                    clonemesage.mesage = data
                    setMesage(clonemesage)
            })
        }else{
            clonemesage.mesage = "Les champs Nom d'utilisateur et Mot de passe ne doivent pas être vide"
            clonemesage.type = 'nok'
            setMesage(clonemesage)
        }
        
    }
    return (
        <div>
            
            <div>
                <img src="7wonder.png" className='menu_logo'/>
                <Alert mesage = {Mesage}></Alert>
                <h1>Crée un compte</h1>
                <form onSubmit={(e) => { submit(e) }}>
                    <div className='groupe-form flex'>
                            <label htmlFor="username">Nom d'utilisateur: </label>
                            <input type="text" name='username' id='username' required/>
                    </div>
                    <div className='groupe-form flex'>
                            <label htmlFor="password">Mot de passe: </label>
                            <input type="password" name='password' id='password' required/>
                    </div>
                    <div className='flex groupe-btn'>
                        <Link to='/login'><button type='button' className='btn'><img src="/back.png" alt="retour" /></button></Link> 
                        <button className='btn-50' type="submit">Crée un compte</button>
                    </div>
                </form>
            </div>
        </div>
        
    );
};

export default Singin;