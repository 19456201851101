
import React, { useCallback } from "react"
import { useEffect } from "react"
/* import { useRef } from "react" */
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { score } from "../../App"
import Cartouche from "../../component/scores/Cartouche"
import Main from "../../component/scores/Main"
import socket from "../../soket/Socket"
export default function Score(){
const nav = useNavigate()
const [loading, setLoading] = useState(true)
const [merveille,setMerveille] = useState(undefined)
    useEffect(()=>{
        socket.emit('get-merveille',(reponce)=>{
            console.log(reponce)
            setMerveille(reponce)
            setLoading(false)   
        })

    },[])


    const [chat, SetChat] = useState([false])
    const [Cartebleu, SetCartebleu] = useState(0)
    const [carteChat, SetCarteChat] = useState(0)
    const [militaire, SetMilitaire] = useState(0)

    const [urbanisme, SetUrbanisme] = useState([false])
    const [artisana, SetArtisana] = useState([false])
    const [joaillerie, SetJoaillerie] = useState([false])
    const [propagande, SetPropagande] = useState([false])
    const [science, SetSience] = useState([false])
    const [architecture, SetArchitecture] = useState([false])
    const [economie, Seteconomie] = useState([false])
    const [ingenierie, SetIngenierie] = useState([false])
    const [tactique, SetTactique] = useState([false])
    const [decoration, SetDecoration] = useState([false])
    const [politique, SetPolitique] = useState([false])
    const [stretagie, SetStrategie] = useState([false])
    const [education, SetEducation] = useState([false])
    const [culture, SetCulture] = useState([false,false])
    
    
    const civilMilitaire ={
        chat : [chat, SetChat], 
        carteChat : [carteChat, SetCarteChat],
        carteBleue : [Cartebleu, SetCartebleu],
        militaire : [militaire, SetMilitaire], 
    }
    const Science ={
        urbanisme : [urbanisme, SetUrbanisme], 
        artisana : [artisana, SetArtisana],
        joaillerie :[joaillerie, SetJoaillerie],
        propagande : [propagande, SetPropagande], 
        science : [science, SetSience], 
        architecture : [architecture, SetArchitecture], 
        economie : [economie, Seteconomie], 
        ingenierie : [ingenierie, SetIngenierie], 
        tactique : [tactique, SetTactique], 
        decoration : [decoration, SetDecoration], 
        politique : [politique, SetPolitique], 
        stretagie : [stretagie, SetStrategie], 
        education : [education, SetEducation], 
        culture : [culture, SetCulture], 
    }
    const [etage, setEtage] = useState([])
    
    const calculer = useCallback((etages)=>{
        let smerveille = 0
        let scivil = 0
        let smilitaire = 0
        let ssience = 0
        let total = 0
        etages.length = merveille.etages.length
        for (let index = 0; index < etages.length; index++) {
            if(etages[index] === undefined){
                etages[index] = false
            }
            
            
        }
        etages.map((element,i)=>{
            if(element){
                smerveille += merveille.etages[i].point
            }
        })
        if(chat[0]){
            scivil += 2
        }
        scivil += (Cartebleu * 3) + (carteChat * 2)
        smilitaire = militaire * 3
        let construie = etages.indexOf(false) < 0 ? false : true  
        if(decoration[0]){
            ssience += construie ? 4 : 6
        }
        if(politique[0]){
            ssience += parseInt(carteChat) 
        }
        if(stretagie[0]){
            ssience += parseInt(militaire) 
        }
        let progres = 0 
        Object.keys(Science).forEach(key => {
            Science[key][0].forEach(progre=>{
                progres += progre ? 1 : 0
            })
        });
        if(education[0]){
            ssience += progres * 2
        }
        if(culture[0] && culture[1]){
            ssience += 12
        }else if(culture[0] || culture[1]){
            ssience += 4
        }
        console.log(score)
        total = ssience + smilitaire + scivil + smerveille
        console.log(total)
        score.civil = scivil
        score.merveille = smerveille
        score.militaire = smilitaire
        score.science = ssience
        score.total = total
        console.log(score)
        socket.emit('update-score',score)
        nav('/7wonder/partie/clasement')
        
    })
    if(loading){
        return(
            <div>loading</div>
        )
    }else{
        return(
            <div className="score">
                
                <Cartouche nom={merveille.nom} description={merveille.description}></Cartouche>
                <Main merveille={merveille} etage={etage} setEtage={setEtage} civilMilitaire={civilMilitaire} science={Science}></Main>
                <div>
                    <button className="calculer" onClick={()=>{calculer(etage)}}>Calculer</button>
                </div>
            </div>
        )
    }

    
    
}