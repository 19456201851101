import React from 'react';
import { useContext } from 'react';
import { Link, Navigate,  Outlet } from 'react-router-dom';
import { authcontext } from '../../contexte/auth/AuthContext';

const Administration = () => {
    const {user} = useContext(authcontext)
    console.log(user)
    if(user.role != "admin"){
        console.log('notr admin')
        return <Navigate to="/home"/>
    }else{
        <Navigate to="/admin/menu"/>
    }
    return (
        <div>
            
            <Outlet></Outlet> 
        </div>
    );
};

export default Administration;