import React from 'react';
import JouerRow from '../loby/JouerRow';

const MheuScorerow = (joueur)=> {

    console.log('joueur',joueur.joueur)
    console.log('manche',joueur.manche)
    let manche
    let copyJoueurs = {...joueur}
    console.log(copyJoueurs)
    if(joueur.manche === undefined){
        manche = 0
    }else{
        manche = joueur.manche
    }
    let na = false
    if(manche>copyJoueurs.joueur.score.manche.length){
        na = []
        for (let i = 0; i < manche - copyJoueurs.joueur.score.manche.length; i++) {
            na.push({score:'N/A',N:copyJoueurs.joueur.score.manche.length+1+i})
            
        }
    }
    console.log('na',na)
    
    return (
        <tr>
            <td >
                <div className="couleur" data-color = {copyJoueurs.joueur.couleur}>{copyJoueurs.joueur.username} </div>
            </td>
            {copyJoueurs.joueur.score.manche.map((score)=>(
                <td key={copyJoueurs.joueur.username + score.N}>{score.score}</td>
            ))}
            {na!=false && na.map((score)=>(
                <td key={copyJoueurs.joueur.username + score.N}>{score.score}</td>
            ))}
            

            <td className="couleur" data-color = {copyJoueurs.joueur.couleur}>{copyJoueurs.joueur.score.total} ({copyJoueurs.joueur.score.total-67})</td>
        </tr>
    );
}

export default MheuScorerow;