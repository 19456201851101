import React from 'react';
import Card from './Card';

const Science = ({science}) => {
    return (
        <div className='sience flex'>
            <Card styles ={'progre'} state={science.urbanisme} type={'checkbox'} n={1}>
            <span className="nom">Urbanisme:</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Urbanisme.png"alt=""/></div>
                            <span className="effet">Lorsque vous
                                prenez une carte <span className="gris">grise</span> bois ou argile, choisissez 1 carte
                                suplementairee parmi les trois
                                disponibles et posez-la devant vous.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.artisana} type={'checkbox'} n={1}>
                <span className="nom">Artisanat</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Artisanat.png"
                                    alt=""/></div>
                            <span className="effet">Lorsque vous
                                prenez une carte <span className="gris">grise</span> <span className="souligner">papier ou fiole</span> , choisissez 1 carte
                                suplementaire parmi les trois
                                disponibles et posez-la devant vous.</span>
                </Card>
                <Card styles ={'progre'} state={science.joaillerie} type={'checkbox'} n={1}>
                <span className="nom">Joaillerie</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Joaillerie.png"
                                    alt=""/></div>
                            <span className="effet">Lorsque vous
                                prenez une carte <span className="gris">grise</span> brique ou une carte <span
                                    className="jaune">jaune</span>, choisissez 1 carte suplementaire parmi les
                                trois disponibles et posez-la devant vous.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.science} type={'checkbox'} n={1}>
                <span className="nom">Science</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/science.png"
                                    alt=""/></div>
                            <span className="effet">Lorsque vous
                                prenez
                                une carte <span className="vert">verte</span> , choisissez 1 carte suplementaire parmi les trois
                                disponibles et posez-la
                                devant
                                vous.</span>
                </Card>
                <Card styles ={'progre'} state={science.propagande} type={'checkbox'} n={1}>
                <span className="nom">Propagande</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Propagande.png"
                                    alt=""/></div>
                            <span className="effet">Lorsque vous
                                prenez une carte <span className="rouge">rouge</span> avec une ou deux corne(s), choisissez 1 carte
                                suplementaire parmi les
                                trois
                                disponibles et posez-la devant vous.</span>
                </Card>
                <Card styles ={'progre'} state={science.architecture} type={'checkbox'} n={1}>
                <span className="nom">Architecture </span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Architecture.png"
                                    alt=""/></div>
                            <span className="effet">Lorsque
                                vous
                                construisez une étape, choisissez 1 carte parmi les trois disponibles et posez-la
                                devant vous.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.economie} type={'checkbox'} n={1}>
                <span className="nom">Economie</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Economie.png"
                                    alt=""/></div>
                            <span className="effet">Une carte
                                jaune en
                                votre possession vous offre 2 piéces au lieu d'une seule
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.ingenierie} type={'checkbox'} n={1}>
                <span className="nom">Ingénierie</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/ingegnirie.png"
                                    alt=""/></div>
                            <span className="effet">Lorsque vous
                                construisez une étape, vous pouvez utiliser n'importe quelle resource sans tenir compte de la
                                restriction "identique" ou "différente"
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.tactique} type={'checkbox'} n={1}>
                <span className="nom">Tactique</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Tactique.png"
                                    alt=""/></div>
                            <span className="effet">Ajoutez 2
                                boucliers
                                à votre total de boucliers.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.decoration} type={'checkbox'} n={1}>
                <span className="nom">Décoration</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Décoration.png"
                                    alt=""/></div>
                            <span className="effet">A la fin de
                                la
                                partiee, gagnez 4 points de victoire si votre merveille est encore en contruction ou 6 si elle
                                est
                                terminée.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.politique} type={'checkbox'} n={1}>
                <span className="nom">Politique</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/politique.png"
                                    alt=""/></div>
                            <span className="effet">A la fin de
                                la
                                partie, gagnez 1 point de victoire par icône CHAT, présente sur vos cartes <span className="bleu">bleues</span>.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.stretagie} type={'checkbox'} n={1}>
                <span className="nom">Stratégie</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Strategie.png"
                                    alt=""/></div>
                            <span className="effet">A la fin de
                                la
                                partie, gagnez 1 point de victoire par jeton de victoire militaire en votre possession.
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.education} type={'checkbox'} n={1}>
                <span className="nom">Education</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Education.png"
                                    alt=""/></div>
                            <span className="effet">A la fin de
                                la
                                partie, gagnez 2 points de victoire par jeton progrès en votre possession (celui-ci y
                                compris).
                            </span>
                </Card>
                <Card styles ={'progre'} state={science.culture} type={'checkbox'} n={2}>
                <span className="nom">Culture</span>
                            <div className="img"><img src="https://jeux-api.edo.ovh/IMG/Culture.png"
                                    alt=""/></div>
                            <span className="effet">Ce jeton est
                                présent en 2 exemplaires. A la fin de la partie, gagnez 4 points de victoire si vous en
                                possédez 1
                                ou 12 points de victoire si vous possédez les 2.
                            </span>
                </Card>


        </div>
        
    );
};

export default Science;