import React from 'react';
import { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { authcontext } from '../../contexte/auth/AuthContext';

const Homewonder = () => {
    const {user} = useContext(authcontext)
    console.log(user)
    return (
        <div className='flex menu'>
          <img src="/7wonder.png" className='menu_logo'/>
          <li>
            <ul><Link to='/7wonder/rejoindre'><button className='menu-btn'>Rejoindre une partie</button></Link> </ul>
            <ul><Link to='/7wonder/cree'><button className='menu-btn'>Crée une partie</button></Link></ul>
            {user.role === 'admin' && <ul><Link to='/admin/menu'><button className='menu-btn'>Administration</button></Link> </ul>} 
          </li>
           
          
          
        </div>
    );
};

export default Homewonder;