import React from 'react';

const Alert = ({mesage}) => {
    return (
        <div>
            {mesage.mesage && 
                <div className={mesage.type + ' mesage flex'}>
                    <span>{mesage.mesage}</span>
                </div>
            }
        </div>
    );
};

export default Alert;