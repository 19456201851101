import { io} from "socket.io-client"



const socket = new io('https://jeux-api.edo.ovh',{
    autoConnect: true,
    withCredentials: true,
    reconnection: true,
    reconnectionDelay: 200,
    
})

export default socket