import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MheuJouerRow from '../../component/6quiprend/MheuJouerRow';
import JouerRow from '../../component/loby/JouerRow'; 
import { authcontext } from '../../contexte/auth/AuthContext';
import { mheupartiecontext } from '../../contexte/auth/MheuPartieContext';
import socket from '../../soket/Socket';
import { mesageContext } from "../../contexte/mesage/MesageContext"
import Alert from '../../component/Alert';
const MheuLoby = () => {
    const nav = useNavigate()
    const {partie,joueurs,setJoueurs,loading,setLoging,color,setColor} = useContext(mheupartiecontext)
    const {user} = useContext(authcontext)
    const {Mesage, setMesage} = useContext(mesageContext)
    function pret(etat){
        console.log('pret')
        let copymesage = {...Mesage}
        copymesage.type = undefined
        copymesage.mesage = false
        setMesage(copymesage)
        let clonejoueurs =[...joueurs]
        const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === user.id)

        if(clonejoueurs[index].couleur === 'N/A'){
            copymesage.type = 'nok'
            copymesage.mesage = 'Choisisez une couleur.'
            setMesage(copymesage)
        }else{
            if(etat !==undefined){
                clonejoueurs[index].pret = etat  
            }else{
                clonejoueurs[index].pret = !clonejoueurs[index].pret
            }
            setJoueurs(clonejoueurs)
            socket.emit('mheu/pret',clonejoueurs[index].pret)
        }

    }

    function start (){
        let copymesage = {...Mesage}
        copymesage.type = undefined
        copymesage.mesage = false
        setMesage(copymesage)
        socket.emit('mheu/start',(mesage)=>{
            setMesage(mesage) 
        })
    }

    
    useEffect(()=>{
        let copymesage = {...Mesage}
        copymesage.type = undefined
        copymesage.mesage = false
        setMesage(copymesage)
        socket.on('mheu/update-satue',(update)=>{
            console.log('update',update)
            let clonejoueurs =[...joueurs]
            const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === update.id)
            console.log(index)
            console.log(joueurs)
            if(index > -1){
                clonejoueurs[index].pret = update.satut
            }
            setJoueurs(clonejoueurs)
            console.log('update2')
        })

        socket.on("mheu/new-player",(joueur)=>{
            const clonejoueurs = [...joueurs]
            clonejoueurs.push(joueur)
            setJoueurs(clonejoueurs)
        })

        socket.on('mheu/startt',()=>{
            console.log('start')
            window.location.reload()
        })

        socket.on('mheu/update-mode',({mode,id_utilisateur})=>{
            console.log('update-mode',mode,id_utilisateur)
            let clonejoueurs =[...joueurs]
            const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === id_utilisateur)
            clonejoueurs[index].mode = mode
            console.log('update-mode',clonejoueurs)
            setJoueurs(clonejoueurs)
        }) 

        socket.on('mheu/update-color',({couleur,id_utilisateur})=>{
            let clonejoueurs =[...joueurs]
            const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === id_utilisateur)
            clonejoueurs[index].couleur = couleur
            console.log('update-couleur',clonejoueurs)
            setJoueurs(clonejoueurs)
        })

        socket.on('mheu/update-liste-color',(color)=>{
             
            console.log('upcolor',color)
            setColor(color)
        })

        return ()=>{
            socket.off('mheu/update-satue')
            socket.off('mheu/new-player')
            socket.off('mheu/startt')
            socket.off('mheu/update-mode')
            socket.off('mheu/update-color')
        }
    },[joueurs,setJoueurs,nav])

    const setcolor = useCallback((couleur)=>{
        let clonejoueurs =[...joueurs]
            const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === user.id)
            console.log(index)
            console.log(joueurs)
            if(index > -1){
                clonejoueurs[index].couleur = couleur
            }
            setJoueurs(clonejoueurs)
            socket.emit('mheu/setColor',couleur)
    },[joueurs,setJoueurs])
    

    
    if(loading){
        return(
            <div className="loaging">
                loading
            </div>
        )
    }else{

        return (
            <div>
                <Alert mesage={Mesage}></Alert>
                <table>
                    <thead>
                        <tr><th colSpan='5'>{partie.nom}</th></tr>
                        <tr>
                            <th colSpan='4'>Nombre de joueur</th>
                            <td>{joueurs.length}/10</td>
                        </tr>
                        <tr>
                            <th colSpan='3'>Crée par</th>
                            <td colSpan='2'>{partie.username}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><th colSpan='5'>joueur</th></tr>
                        <tr>
                            <th colSpan='2'>Nom</th>
                            <th colSpan='1'>Couleur</th>
                            <th colSpan='1'>Saisi des points</th>
                            <th colSpan='1'>Statut</th>
                        </tr>
                        
    
                        {
                            joueurs.map((joueur)=>(<MheuJouerRow key={joueur.id_utilisateur} setColor= {setcolor} colors={color} joueur={joueur} pret={pret}></MheuJouerRow>))
                        }
                    </tbody>
    
                </table>
                <div className='groupe-btn flex'>
                    <button className='btn-50' onClick={()=>{pret()}}>Pret</button>
                    {partie.id_utilisateur === user.id ? <button className='btn-50' onClick={()=>{start()}}>Commencer</button>:""}
                </div>
            </div>
            
        );
    }
};

export default MheuLoby;