import React from 'react';

const Etage = ({etage , n, state, setState,nom}) => {
    const id = "etage" + n
    /* console.log("render etage") */
    function hendelClick (){
        
        let clonestate = [...state]
        
        let newState = !clonestate[n]
        clonestate[n] = newState
        
        setState(clonestate)
    }
    function img(s) {
        return s[n] ? '/merveille/Img/'+ nom + '/F'+ (n+1)+'.png' : '/merveille/Img/'+ nom + '/C'+ (n+1)+'.png'
    }
    return(
        <div className="flex etage" id={id} onClick={hendelClick}>
                <img src={img(state)} alt=""/>
        </div>
    )
};

export default Etage;