import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import Cardfin from '../../component/fin/Cardfin';
import NouveauTour from '../../component/fin/NouveauTour';
import socket from '../../soket/Socket';
import { authcontext } from '../../contexte/auth/AuthContext';
import { partiecontext } from '../../contexte/auth/PartieContext'
import Loby from './Loby';
import { useNavigate } from 'react-router-dom';

const Fin = () => {
    const nav  = useNavigate()
    const {partie} =  useContext(partiecontext)
    const {user} = useContext(authcontext)
    

    const [clasement,setClasement] = useState([])
    useEffect(()=>{
        socket.emit('get-clasement',(clasement)=>{
            setClasement(clasement)
        })
    },[])

    useEffect(()=>{
        socket.on('home',()=>{
            console.log('home')
            nav('/home')
        })

        return ()=>{
            socket.off('home')
        }
    },[nav])

    function terminer(){
        socket.emit('terminer')
    }
   /*  socket.on('startt',()=>{
        console.log('start')
        nav('/7wonder/partie/score')
    }) */

    return (
        <div>
            <section className="clasement">

                {
                    clasement.map(element=>(<Cardfin key={element.type} img={element.img} type={element.type} nom={element.nom} pts={element.pts}></Cardfin>))
                }
            </section>

            {user.id === partie.id_utilisateur && <button onClick={()=>{terminer()}}>Terminer</button>}
                <div className='flex centre'>
                    {<Loby></Loby>}
                </div>
        

        </div>
    );
};

export default Fin;