import Etage from './Etage';

const Merveille = ({etages, nom, state, setState}) => {
    let li_etages = [];
    etages.forEach((etage,key)=>{
        
            li_etages.unshift(<Etage key={key} state={state} setState={setState} etage={etage} n={key} nom ={nom}/>)
        });
    
    return(
        <div id={nom} className="flex merveille_div" >
            {li_etages}
        </div>
    )
};

export default Merveille;