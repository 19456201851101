import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import socket from '../../soket/Socket';
import { createContext } from 'react';

export const partiecontext = createContext()

const PartieContext = () => {
    const nav = useNavigate()
    const [partie,setPartie] = useState({})
    const [joueurs,setJoueurs] = useState([])
    useEffect(()=>{
        socket.emit('get-info-partie', (reponce)=>{
            if(!reponce.err){
                setPartie(reponce.partie)
                setJoueurs(reponce.joueurs)
                console.log(reponce.partie.statut)
                console.log(reponce)
                nav(`/7wonder/partie/${reponce.partie.statut}`)
            }else{
                nav("/rejoindre")
            }
            
        })
    },[])

    return (
        <partiecontext.Provider value={{partie,joueurs,setJoueurs,setPartie}}>
            <Outlet></Outlet>
        </partiecontext.Provider>
    );
};

export default PartieContext;