import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Alert from '../component/Alert';
import { authcontext } from '../contexte/auth/AuthContext';
import { mesageContext } from '../contexte/mesage/MesageContext';


const Login = () => {
    const [err, setErr] = useState("")
    const {setUser,user, } = useContext(authcontext)
    const {Mesage, setMesage,} = useContext(mesageContext)
    const nav = useNavigate()

    useEffect(()=>{
        return()=>{
            let clonemesage = {...Mesage}
            clonemesage.mesage = false
            clonemesage.type = undefined
            setMesage(clonemesage)
        }
    },[/* Mesage,setMesage */])

    function conect(e) {
        let clonemesage = {...Mesage}
            clonemesage.mesage = false
            clonemesage.type = undefined
            setMesage(clonemesage)
        e.preventDefault()
        const form = new FormData(e.target)
        const body = {
            uername: form.get('username'),
            password: form.get('password'),
            stay: form.get('stay'),
        }
        fetch('https://jeux-api.edo.ovh/login',{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            method : 'post',
            body : JSON.stringify(body) ,
            credentials: 'include'
            
        }).then((res)=>{
            if(res.ok){
                return res.json()
            }else{
                return res.text()
            }
              
        }).then((data)=>{
            if(data.id){
                setUser(data)
                nav('/home')
            }else{
                console.log(data)
                clonemesage = {...Mesage}
                clonemesage.mesage = data
                clonemesage.type = 'nok'
                setMesage(clonemesage)
            }
        })
    }
    return (
        
        <div>
            {user?<Navigate to='/home'/>: 
            <div>
                <img src="7wonder.png" className='menu_logo'/>
                <Alert mesage = {Mesage}></Alert>
            <h1>Se conecter</h1>
            <form onSubmit={(e) => { conect(e) }}>
                <div className='groupe-form flex'>
                        <label htmlFor="username">Nom d'utilisateur: </label>
                        <input type="text" name='username' id='username' />
                </div>
                <div className='groupe-form flex'>
                        <label htmlFor="password">Mot de passe: </label>
                        <input type="password" name='password' id='password' />
                </div>
                <div className='checkbox flex'>
                        <label htmlFor="stay">Restez conecté(e): </label>
                        <input type="checkbox" name='stay' id='stay' />
                </div>
                <div className='flex groupe-btn'>
                    <Link to='/singin'><button type='button' className='btn'>Cree compte</button></Link> 
                    <button className='btn-50' type="submit">Se connecter</button>
                </div>
            </form>
            </div>
            /* {statu && <span>conecter</span>} */ }
            
        </div>
        
        
    );
};

export default Login;