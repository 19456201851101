import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import CardPartie from '../../component/admin/CardPartie';
import socket from '../../soket/Socket';

const GererPartieMheu = () => {
    const [parties,setParties] = useState(undefined) 
    useEffect(()=>{
        socket.emit('mheu/get-partie-admin',(parties)=>{
            setParties(parties)
        })
    },[])
    const changeStatue = useCallback((statue,id)=>{
        socket.emit('mheu/changeStatue',statue,id)
    },[])
    if(parties === undefined){
        return(
            <div>LOADING</div>
        )
    }
    return (
        <div>
            {parties.map((partie)=><CardPartie key={partie.id} partie={partie} changeStatue={changeStatue}></CardPartie>)}
        </div>
    );
};

export default GererPartieMheu;