import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';


export const authcontext = createContext()
const AuthContext = ({children}) => {
    const nav = useNavigate()
    const [user, setUser] = useState(false)
    const [login, setLogin] = useState(false)
    useEffect(()=>{
        fetch('https://jeux-api.edo.ovh/isconect',{
            credentials: 'include'
        }).then((res)=>{
            if(res.ok){
                return res.json()
            }
        }).then((data)=>{
            if(data){
                setUser(data)
            }
            setLogin(true)
        })
    },[])
    
    return (
       <authcontext.Provider value={{user, setUser}}>
            {login?children:<h1>loading</h1>}
       </authcontext.Provider>
    );
};

export default AuthContext;