import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../component/Alert';
import { mesageContext } from '../../contexte/mesage/MesageContext';
import socket from '../../soket/Socket';
const MheuRejoindre = () => {
    const [rejoindre, setRejoindre] = useState([])
    const [continuer, setContinuer] = useState([])
    const nav = useNavigate()
    const {Mesage, setMesage} = useContext(mesageContext)

    useEffect(() => {
        socket.emit('mheu/get-partie', (parties) => {
            setRejoindre(parties.rejoindre)
            setContinuer(parties.continuer)
        })
        /* rejoindre = []
        continuer = []
        parties.forEach((partie) => {
            if (partie.joueur.includes(ID)) {
                rejoindre.push(partie)
            } else {
                continuer.push(partie)
            }
        })
        console.log(rejoindre) */
    }, [])

    useEffect(()=>{
        
        socket.on('mheu/new_game',(partie)=>{
            setRejoindre([...rejoindre,partie])
        })

        socket.on('mheu/del_partie',(id)=>{
            let cloneRejoindre = [...rejoindre]
            const i = cloneRejoindre.findIndex((e)=>e.id === id)
            cloneRejoindre.splice(i,1)
            setRejoindre(cloneRejoindre)
        })
        
        return ()=>{
            socket.off('mheu/new_game')
            socket.off('mheu/del_partie')
        }

    },[rejoindre,setRejoindre])

    const join = useCallback((e)=>{
        let clonemesage = {...Mesage}
            clonemesage.mesage = false
            clonemesage.type = undefined
            setMesage(clonemesage)
        const id = e.target.dataset.id
        socket.emit('mheu/rejoindre',id,(ok)=>{
            if(ok){
                nav('/6quiprend/partie')
            }else{
            clonemesage = {...Mesage}
            clonemesage.mesage = "Partie complète ne réessayer pas"
            clonemesage.type = "nok"
            console.log(clonemesage)
            setMesage(clonemesage)
            }
        })
    },[nav]) 

    return (
        <div>
            <div>
                <Alert mesage={Mesage}/>
                <h1>Rejoindre</h1>
                {
                    rejoindre.map((partie) => (
                        <div key={partie.id} className='flex card-partie'>
                            <div className='nom-partie'>Nom de la partie : {partie.nom}</div>
                            <div className='info-partie'>
                                <div>Cree par : {partie.username}</div>
                                <div>Nombre de joueurs {partie.NB_joueur}/10</div>
                            </div>

                            <div className='btn-partie flex centre'><button onClick={(e)=>{join(e)}} data-id={partie.id}>Rejoindre</button></div>
                        </div>
                    ))
                }
            </div>
            <div>
                <h1>Continuer</h1>
                {
                    continuer.map((partie) => (
                        <div key={partie.id} className='flex card-partie'>
                            <div className='nom-partie'>Nom de la partie : {partie.nom}</div>
                            <div className='info-partie'>
                                <div>Cree par : {partie.username}</div>
                                <div>Nombre de joueurs : {partie.NB_joueur}/10{/*  */}</div>
                            </div>

                            <div className='btn-partie flex centre'><button onClick={(e)=>{join(e)}} data-id={partie.id}>Continuer</button></div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default MheuRejoindre;