import React from 'react';

const JouerRow = ({joueur}) => {
    return (
        <tr>
            <td colSpan='2'>{joueur.username}</td>
            <td className={joueur.pret ? "Pret": 'Nom_pret'} colSpan='2'>{joueur.pret ? "Pret": 'Non pret'}</td>
        </tr>
    );
};

export default JouerRow;