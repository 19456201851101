import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import JouerRow from '../../component/loby/JouerRow'; 
import { authcontext } from '../../contexte/auth/AuthContext';
import { partiecontext } from '../../contexte/auth/PartieContext';
import socket from '../../soket/Socket';
const Loby = () => {
    const nav = useNavigate()
    const {partie,joueurs,setJoueurs} = useContext(partiecontext)
    const {user} = useContext(authcontext)
    function pret(){
        console.log('pret')
        let clonejoueurs =[...joueurs]
        const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === user.id)
        clonejoueurs[index].pret = !clonejoueurs[index].pret
        setJoueurs(clonejoueurs)
        socket.emit('pret',clonejoueurs[index].pret)
    }

    function start (){
        socket.emit('start')
    }

    
    useEffect(()=>{
        socket.on('update-satue',(update)=>{
            console.log('update',update)
            let clonejoueurs =[...joueurs]
            const index = clonejoueurs.findIndex((e)=>e.id_utilisateur === update.id)
            console.log(index)
            console.log(joueurs)
            if(index > -1){
                clonejoueurs[index].pret = update.satut
            }
            setJoueurs(clonejoueurs)
            console.log('update2')
        })

        socket.on("new-player",(joueur)=>{
            const clonejoueurs = [...joueurs]
            clonejoueurs.push(joueur)
            setJoueurs(clonejoueurs)
        })

        socket.on('startt',()=>{
            console.log('start')
            nav('/7wonder/partie/score')
        })

        return ()=>{
            socket.off('update-satue')
            socket.off('new-player')
            socket.off('startt')
        }
    },[joueurs,setJoueurs,nav])
    

    

    return (
        <div>
            <table>
                <thead>
                    <tr><th colSpan='4'>{partie.nom}</th></tr>
                    <tr>
                        <th>Distribution</th>
                        <td>{partie.regle_aleatoire ? "Sans répetition": "Avec répetition"}</td>
                        <th>Nombre de joueur</th>
                        <td>{partie.NB_joueur_max}/7</td>
                    </tr>
                    <tr>
                        <th colSpan='2'>Crée par</th>
                        <td colSpan='2'>{partie.username}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><th colSpan='4'>joueur</th></tr>
                    <tr>
                        <th colSpan='2'>Nom</th>
                        <th colSpan='2'>Statut</th>
                    </tr>

                    {
                        joueurs.map((joueur)=>(<JouerRow key={joueur.id_utilisateur} joueur={joueur}></JouerRow>))
                    }
                </tbody>

            </table>
            <div className='groupe-btn flex'>
                <button className='btn-50' onClick={()=>{pret()}}>Pret</button>
                {partie.id_utilisateur === user.id ? <button className='btn-50' onClick={()=>{start()}}>Commencer</button>:""}
            </div>
        </div>
        
    );
};

export default Loby;