import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { authcontext } from '../../contexte/auth/AuthContext';

const Wonder = () => {
    const {user} = useContext(authcontext)
    const nav = useNavigate()
    console.log(user)
    useEffect(()=>{

      nav('/7wonder/home')
    },[])
    return (
        <div className='flex menu'>
          
          <Outlet></Outlet>
          
        </div>
    );
};

export default Wonder;